<template>
    <div class="addDrivers-styles">
        <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

        <div class="main-content">
            <div class="middle-content pb-5 pb-sm-0">
                <div class="header-txt-content align-center mb-7" @click="navigateTo()">
                    <img class="back-icon" src="../assets/icons/black-back-arrow.png">
                    <span class="back-btn-txt">
                        {{ (route_comingFrom === 'shipDetail' ? "Back to shipment details" : "Back to driver list") }}
                    </span>
                </div>

                <p class="form-title-txt">
                    Add Driver
                </p>
                <div class="title-info d-flex align-center align-start mb-5">
                    <img src="../assets/icons/info-icon.png">
                    <span>This will send the driver an invite to the Driver App for load tracking.</span>
                </div>

                <v-form>
                    <div class="input-form-content">

                        <div class="full-name-fields d-flex justify-space-between">
                            <div class="width-divider">
                                <label class="label-txt">
                                    First name
                                </label>
                                <v-text-field @input="enterFirstname()" @keydown="filterSpecialCharacter($event)"
                                    v-model="firstName" class="input-field-setting" placeholder="Billy" solo dense
                                    background-color="#F4F4F4" flat hide-details="true"></v-text-field>

                                <p class="error-msg mb-3">{{ errorFirstnameMsg }}</p>
                            </div>

                            <div class="width-divider">
                                <label class="label-txt">
                                    Last name
                                </label>
                                <v-text-field @input="enterLastname()" @keydown="filterSpecialCharacter($event)"
                                    v-model="lastName" class="input-field-setting" placeholder="Bob" solo dense
                                    background-color="#F4F4F4" flat hide-details="true"></v-text-field>

                                <p class="error-msg mb-3">{{ errorLastnameMsg }}</p>
                            </div>
                        </div>

                        <div class="mb-5">
                            <label class="label-txt">
                                Mobile number
                            </label>

                            <div class="d-flex justify-space-between">
                                <div class="dial-code-input">
                                    <v-select class="input-field-setting dial-select" v-model="selectedCode"
                                        hide-details="true" :items="dialCodes" label="" solo dense flat
                                        background-color="#F4F4F4">

                                        <template v-slot:append>
                                            <img src="@/assets/icons/dropdown-icon.png" class="dropdown-icon" />
                                        </template>
                                    </v-select>
                                </div>

                                <div class="phone-field">
                                    <v-text-field @input="enterPhone($event)" @keydown="checkNumber($event)" type="text"
                                        v-model="mobileNo" class="input-field-setting" placeholder="(432) 400-4121" solo
                                        dense background-color="#F4F4F4" flat hide-details="true"></v-text-field>

                                    <p class="error-msg mb-3">{{ errorPhoneMsg }}</p>
                                </div>
                            </div>
                        </div>

                        <v-btn @click.stop="confirmationDialog = true" type="button" class="invite-btn white--text"
                            height="39px" block color="black"
                            :disabled="firstName === '' || lastName === '' || !validPhoneNo">Invite</v-btn>
                    </div>
                </v-form>
            </div>
        </div>

        <v-dialog v-model="confirmationDialog" persistent max-width="440">
            <v-card class="pa-6 accept-order-vcard">
                <v-card-title class="confirmation-main-title mb-3 pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between">
                    Please confirm you wish to add the user {{ firstName }} {{ lastName }}
                </v-card-title>

                <v-card-actions class="pa-0">
                    <v-spacer></v-spacer>
                    <v-btn class="accept-order-btns rounded-md" width="50%" dense outlined text
                        @click="confirmationDialog = false">
                        No
                    </v-btn>

                    <v-btn class="accept-order-btns white--text rounded-md" dense width="50%" dark color="black"
                        @click="confirmationDialog = false; addDriver()">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <AppLoading :active.sync="isLoading" />
    </div>
</template>
  
<style scoped lang="scss">
.addDrivers-styles {
    @import '../assets/styles/AddDrivers.scss';

    font-family: 'Outfit', sans-serif;

    .inter-font-family {
        font-family: 'Inter', sans-serif !important;
    }

    .outfit-font-family {
        font-family: 'Outfit', sans-serif !important;
    }
}
</style>
    
<script>
import { phone } from 'phone';
import mixin from "../mixins/auth.mixin.js";

import allPhoneCodes from "../assets/json/phone-codes.json";
import MobileHeader from '../components/mobile-header.vue';

export default {
    name: 'AddDrivers',
    components: {
        MobileHeader,
    },
    data: () => ({
        authMixins: mixin.methods,
        phoneCodes: allPhoneCodes,
        dialCodes: [],
        selectedCode: "+1",
        tabNumber: -1,
        shipmentID: null,
        requestStatus: false,
        confirmationDialog: false,
        route_comingFrom: "",
        validPhoneNo: false,
        isLoading: true,

        firstName: '',
        lastName: '',
        mobileNo: '',

        errorFirstnameMsg: '',
        errorLastnameMsg: '',
        errorPhoneMsg: '',
    }),
    created() {
        this.dialCodes = this.phoneCodes.countries.map(country => {
            return {
                'value': country.code,
                'text': country.code,
                'code': country.alphaCode
            };
        });
    },
    mounted() {
        document.title = "Carrier Portal - Breaker19.app";

        this.route_comingFrom = this.$route.params.previousPage;
        this.tabNumber = +(this.$route.params.tabNumber);
        this.shipmentID = this.$route.params.shipmentID;

        this.isLoading = false;
    },

    methods: {
        navigateTo() {
            if (this.route_comingFrom === 'shipDetail') {
                this.$router.push({ "path": `/shipment-details/drivers/${this.tabNumber}/${this.shipmentID}` });
            }
            else this.$router.push("/drivers-list");
        },

        filterSpecialCharacter(event) {
            if (event.keyCode >= 48 && event.keyCode <= 57)
                if (event.shiftKey) event.preventDefault();

            if (event.keyCode === 189 || event.keyCode === 187 || event.keyCode === 192)
                event.preventDefault();
        },

        enterFirstname() {
            if (this.firstName === '') this.errorFirstnameMsg = 'Please enter first name';
            else this.errorFirstnameMsg = '';
        },
        enterLastname() {
            if (this.lastName === '') this.errorLastnameMsg = 'Please enter last name';
            else this.errorLastnameMsg = '';
        },
        enterPhone(value) {
            if (this.mobileNo === '') this.errorPhoneMsg = 'Please enter your phone number';
            else this.errorPhoneMsg = '';

            this.formatToPhone(value);
        },

        checkNumber(event) {
            if (event.keyCode >= 49 && event.keyCode <= 56)
                if (event.shiftKey) event.preventDefault();

            if (event.keyCode === 189 || event.keyCode === 187 || event.keyCode === 192)
                event.preventDefault();

            let char = String.fromCharCode(event.keyCode);
            if (/^[A-Za-z]+$/.test(char)) event.preventDefault();
        },

        removeSpecialCharactersAndSpaces(str) {
            return str.replace(/[^a-zA-Z0-9]/g, '');
        },

        formatToPhone(value) {
            const input = value.replace(/\D/g, '').substring(0, 10);
            const areaCode = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const last = input.substring(6, 10);

            if (input.length > 6) { value = `(${areaCode}) ${middle}-${last}`; }
            else if (input.length > 3) { value = `(${areaCode}) ${middle}`; }
            else if (input.length > 0) { value = `(${areaCode}`; }

            const regex = /^[0-9()\- ]+$/;
            const e164 = /^\+[1-9]\d{1,14}$/;

            if (phone(value).isValid === false || e164.test(this.selectedCode + this.removeSpecialCharactersAndSpaces(this.mobileNo)) === false) {
                this.errorPhoneMsg = 'Phone number invalid';
                this.validPhoneNo = false;
                this.mobileNo = value;
            }
            else {
                if (regex.test(value)) {
                    this.mobileNo = value;
                    this.validPhoneNo = true;
                }
                else {
                    this.$nextTick(() => {
                        this.mobileNo = '';
                    });
                }
            }
        },

        addDriver() {
            if (!this.requestStatus) {
                this.requestStatus = true;

                let alpha_code = "";
                for (let countryCode of this.dialCodes){
                    if (countryCode.value === this.selectedCode){
                        alpha_code = countryCode.code;
                    }
                }

                let userData = {
                    "first_name": this.firstName,
                    "last_name": this.lastName,
                    "phone_number": +this.removeSpecialCharactersAndSpaces(this.mobileNo),
                    "phone_country_code": this.selectedCode,
                    'alpha_country_code': alpha_code
                };

                let config = {
                    headers: {
                        "Authorization": localStorage.authToken,
                    }
                }

                this.$api.post(`/drivers/create-driver`, userData, config)
                    .then(response => {
                        if (response.status === 200 || response.status === 201) {
                            this.$toast.success('Driver invite send successfully', {});
                            this.navigateTo();
                        }
                        else if (response.status === 401) {
                            this.authMixins.clearAuthData();
                            this.$router.push('/signin');
                        }
                        else this.$toast.error('Driver invite failed', {});

                        this.requestStatus = false;
                    }).catch(async (err) => {
                        console.log(err);
                        if (err.response && err.response.status === 401) {
                            this.authMixins.clearAuthData();
                            this.$router.push('/signin');
                        }
                        this.requestStatus = false;
                        if (err.response && err.response.data.message == "The phone number has already been taken.") {
                            this.$toast.error("The phone number has already been taken.", {});
                        } else {
                            this.$toast.error("Driver invite failed", {});
                        }
                    });
            }
        }
    }
}

</script>