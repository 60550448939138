import axios from "axios";

const apiMixin = (() => {
    const cachedApiBaseURL = localStorage.getItem("apiBaseURL");
    const axiosInstance = axios.create({
        baseURL: cachedApiBaseURL ? cachedApiBaseURL : process.env.VUE_APP_API_BASE_URL,
        timeout: 30000,
    });
    localStorage.setItem("apiBaseURL", axiosInstance.defaults.baseURL);

    const accessToken = localStorage.getItem("authToken");
    console.log({accessToken})
    if (accessToken) {
        axiosInstance.defaults.headers.common.Authorization = accessToken;
    }

     axiosInstance.defaults.headers.common.Authorization = localStorage.getItem("token");

    const impersonationToken = localStorage.getItem("impersonationToken");

    if (impersonationToken) {
         axiosInstance.defaults.headers.common["X-Impersonate"] = impersonationToken;
    }

    return {
        computed: {
            $api:  () => axiosInstance
        }
    }
})();

console.log({apiMixin})

export default apiMixin;
