const mixins = {
  methods: {
    saveAuthSignin(status, bearerToken) {
      localStorage.isAuthorized = status;
      localStorage.authToken = ("Bearer " + bearerToken);
    },

    clearAuthData(){
      localStorage.clear();
    }
  },
};

export default mixins;
