<template>
    <div class="editDriver-styles">
        <MobileHeader></MobileHeader>

        <div class="main-content">
            <div class="middle-content pb-5 pb-sm-0">

                <div class="header-txt-content align-center mb-7">
                    <router-link to="/drivers-list" class="remove-decoration d-flex align-center">
                        <img class="back-icon" src="../assets/icons/black-back-arrow.png">
                        <span class="back-btn-txt">Back to driver list</span>
                    </router-link>
                </div>

                <p class="form-title-txt mb-3">
                    Edit Driver
                </p>

                <v-form @submit.prevent="editDriver()">
                    <div class="input-form-content">

                        <div class="mb-2 full-name-fields d-flex justify-space-between">
                            <div class="width-divider">
                                <label class="label-txt">
                                    First name
                                </label>
                                <v-text-field @input="enterFirstname()" @keydown="filterSpecialCharacter($event)" v-model="firstName" class="input-field-setting"
                                    placeholder="Billy" solo dense background-color="#F4F4F4" flat
                                    hide-details="true"></v-text-field>

                                <p class="error-msg mb-3">{{ errorFirstnameMsg }}</p>
                            </div>

                            <div class="width-divider">
                                <label class="label-txt">
                                    Last name
                                </label>
                                <v-text-field @input="enterLastname()" @keydown="filterSpecialCharacter($event)" v-model="lastName" class="input-field-setting"
                                    placeholder="Bob" solo dense background-color="#F4F4F4" flat
                                    hide-details="true"></v-text-field>

                                <p class="error-msg mb-3">{{ errorLastnameMsg }}</p>
                            </div>
                        </div>

                        <div>
                            <label class="label-txt">
                                Mobile number
                            </label>

                            <div class="d-flex justify-space-between">
                                <div class="dial-code-input">
                                    <v-select class="input-field-setting dial-select" v-model="selectedCode"
                                        :items="dialCodes" label="" solo dense flat background-color="#F4F4F4">

                                        <template v-slot:append>
                                            <img src="@/assets/icons/dropdown-icon.png" class="dropdown-icon" />
                                        </template>
                                    </v-select>
                                </div>

                                <div class="phone-field">
                                    <v-text-field @input="enterPhone($event)" @keydown="checkNumber($event)" v-model="mobileNo" class="input-field-setting"
                                        placeholder="(432) 400-4121" solo dense background-color="#F4F4F4" flat
                                        hide-details="true"></v-text-field>

                                    <p class="error-msg mb-3">{{ errorPhoneMsg }}</p>
                                </div>
                            </div>
                        </div>

                        <v-btn type="submit" class="invite-btn white--text" height="39px" block color="black"
                            :disabled="firstName === '' || lastName === '' || !validPhoneNo">Edit</v-btn>
                    </div>

                    <p class="error-msg">{{ responseMsg }}</p>
                </v-form>
            </div>
        </div>
    </div>
</template>
  
<style scoped lang="scss">
.editDriver-styles {
    @import '../assets/styles/EditDriver.scss';

    font-family: 'Outfit', sans-serif;

    .inter-font-family {
        font-family: 'Inter', sans-serif !important;
    }

    .outfit-font-family {
        font-family: 'Outfit', sans-serif !important;
    }
}
</style>
    
<script>
import { phone } from 'phone';
import mixin from "../mixins/auth.mixin.js";

import allPhoneCodes from "../assets/json/phone-codes.json";
import MobileHeader from '../components/mobile-header.vue';

export default {
    name: 'EditDriver',
    components: {
        MobileHeader,
    },
    data: () => ({
        authMixins: mixin.methods,
        phoneCodes: allPhoneCodes,
        dialCodes: [],
        driverData: {},
        selectedCode: "+1",
        requestStatus: false,
        validPhoneNo: false,

        firstName: '',
        lastName: '',
        mobileNo: '',

        errorFirstnameMsg: '',
        errorLastnameMsg: '',
        errorPhoneMsg: '',
        responseMsg: ''
    }),
    created() {
        this.dialCodes = this.phoneCodes.countries.map(country => {
            return {
                'value': country.code,
                'text': country.code,
                'code': country.alphaCode
            };
        });
    },
    mounted() {
        document.title = "Carrier Portal - Breaker19.app";
        this.loadDriverDetail();
    },

    methods: {
        navigateTo() {
            this.$router.push("/drivers-list");
        },
        filterSpecialCharacter(event) {
            if (event.keyCode >= 48 && event.keyCode <= 57) 
                if (event.shiftKey) event.preventDefault();
            
            if (event.keyCode === 189 || event.keyCode === 187 || event.keyCode === 192) 
                event.preventDefault();
        },

        enterFirstname() {
            if (this.firstName === '') this.errorFirstnameMsg = 'Please enter first name';
            else this.errorFirstnameMsg = '';
        },
        enterLastname() {
            if (this.lastName === '') this.errorLastnameMsg = 'Please enter last name';
            else this.errorLastnameMsg = '';
        },
        enterPhone(value) {
            if (this.mobileNo === '') this.errorPhoneMsg = 'Please enter your phone number';
            else this.errorPhoneMsg = '';

            this.formatToPhone(value);
        },

        checkNumber(event) {
            if (event.keyCode >= 49 && event.keyCode <= 56) 
                if (event.shiftKey) event.preventDefault();
            
            if (event.keyCode === 189 || event.keyCode === 187 || event.keyCode === 192) 
                event.preventDefault();

            let char = String.fromCharCode(event.keyCode);
            if (/^[A-Za-z]+$/.test(char)) event.preventDefault();
        },

        removeSpecialCharactersAndSpaces(str) {
            return str.replace(/[^a-zA-Z0-9]/g, '');
        },

        formatToPhone(value) {
            const input = value.replace(/\D/g, '').substring(0, 10);
            const areaCode = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const last = input.substring(6, 10);

            if (input.length > 6) { value = `(${areaCode}) ${middle}-${last}`; }
            else if (input.length > 3) { value = `(${areaCode}) ${middle}`; }
            else if (input.length > 0) { value = `(${areaCode}`; }

            const regex = /^[0-9()\- ]+$/;
            const e164 = /^\+[1-9]\d{1,14}$/;

            if (phone(value).isValid === false || e164.test(this.selectedCode + this.removeSpecialCharactersAndSpaces(this.mobileNo)) === false) {
                this.errorPhoneMsg = 'Phone number invalid';
                this.validPhoneNo = false;
                this.mobileNo = value;
            }
            else {
                if (regex.test(value)) {
                    this.mobileNo = value;
                    this.validPhoneNo = true;
                }
                else {
                    this.$nextTick(() => {
                        this.mobileNo = '';
                    });
                }
            }
        },

        formatToPhoneOnly(value) {
            const input = value.replace(/\D/g, '').substring(0, 10);
            const areaCode = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const last = input.substring(6, 10);
            if (input.length > 6) { value = `(${areaCode}) ${middle}-${last}`; }
            else if (input.length > 3) { value = `(${areaCode}) ${middle}`; }
            else if (input.length > 0) { value = `(${areaCode}`; }

            this.validPhoneNo = true;
            return value;
        },

        loadDriverDetail() {
            let config = {
                headers: {
                    "Authorization": localStorage.authToken,
                }
            }

            this.$api.get(`/drivers/info/${this.$route.params.id}`, config)
                .then(response => {
                    if (response.status === 200) {
                        this.driverData = response.data.data;
                        this.firstName = (response.data.data.first_name ? response.data.data.first_name : 'Not provided');
                        this.lastName = (response.data.data.last_name ? response.data.data.last_name : 'Not provided');
                        this.mobileNo = (response.data.data.phone ? response.data.data.phone : '000000000');
                        this.selectedCode = (response.data.data.country_code ? response.data.data.country_code : '00');

                        this.mobileNo = this.formatToPhoneOnly(this.mobileNo);
                    }
                    else if (response.status === 401){
                        this.authMixins.clearAuthData();
                        this.$router.push('/signin');
                    }
                    else this.$toast.error('Driver data load failed', {});
                }).catch(async (err) => {
                    if (err.response && err.response.status === 401) {
                        this.authMixins.clearAuthData();
                        this.$router.push('/signin');
                    }
                    console.log(err);
                });
        },

        editDriver() {
            if (!this.requestStatus) {
                this.requestStatus = true;

                let alpha_code = "";
                for (let countryCode of this.dialCodes){
                    if (countryCode.value === this.selectedCode){
                        alpha_code = countryCode.code;
                    }
                }

                let userData = {
                    'full_name': this.firstName + " " + this.lastName,
                    'phone_number': +this.removeSpecialCharactersAndSpaces(this.mobileNo),
                    'phone_country_code': this.selectedCode,
                    'alpha_country_code': alpha_code
                };

                let config = {
                    headers: {
                        "Authorization": localStorage.authToken,
                    }
                }

                this.$api.patch(`/drivers/update-driver/${this.driverData.id}`, userData, config)
                    .then(response => {
                        if (response.status === 200) {
                            this.$toast.success('Driver updated successfully', {});
                            this.navigateTo();
                        }
                        else this.$toast.error('Driver update failed', {});
                        this.requestStatus = false;
                    }).catch(async (err) => {
                        console.log(err);
                        this.requestStatus = false;
                        if (err.response && err.response.data.message == "The phone number has already been taken.") {
                            this.$toast.error("The phone number has already been taken.", {});
                        } else {
                            this.$toast.error("Driver update failed", {});
                        } 
                    });
            }
        }
    }
}

</script>