<template>
  <v-app>
    <v-main style="background-color: #f4f4f4;">
      <ImpersonationBar />
      <RouterView />
    </v-main>
  </v-app>
</template>

<script>

import mixin from "./mixins/auth.mixin.js";
import ImpersonationBar from "@/components/impersonation-bar.vue";
export default {
  name: 'App',

  components: {
    ImpersonationBar
  },

  data: () => ({
    authMixins: mixin.methods,
  }),

  mounted() {
    let scriptId = "map-api-script";
    let mapAlreadyAttached = !!document.getElementById(scriptId);
    if (typeof google === 'undefined') {
      if (!mapAlreadyAttached) {
        const mapScript = document.createElement('script');
        mapScript.id = scriptId;
        mapScript.defer = true
        
        mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP}&loading=async&libraries=places&callback=Function.prototype`;
        document.head.appendChild(mapScript);
      }
    }
  },
};
</script>
