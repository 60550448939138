<template>
  <v-row class="ma-0">
    <v-col v-if="!alertDialog && !isLoading" class="pa-0">
      <div class="setpassword-styles">
        <div class="forgot-header">
          <div
            class="d-sm-flex d-md-flex justify-sm-center justify-md-center justify-lg-center justify-xl-center"
          >
            <div class="header-txt-content d-flex align-start align-sm-center">
              <span class="forgot-pwd-txt ml-7 mr-2 ml-sm-2"
                >You were invited</span
              >
            </div>
          </div>

          <div class="main-content">
            <div class="middle-content inter-font-family">
              <p class="content-title">Choose Password</p>
              <div class="password-input-container">
                <p class="sub-title">Password</p>
                <Password
                  v-model="password"
                  :badge="false"
                  :toggle="true"
                  @score="setScore"
                  @feedback="setFeedBack"
                >
                </Password>
                <div v-if="showPasswordAlert" class="password-alert">
                  <v-icon color="red" size="15">mdi-alert-circle</v-icon>
                  <span>{{ passwordAlertDescription }}</span>
                </div>
                <p class="sm-text">
                  Password strength:
                  <span :class="strengthStyle">{{ strength }}</span>
                </p>
              </div>
              <div class="password-input-container">
                <p class="sub-title">Password Confirmation</p>
                <Password
                  v-model="password_confirmation"
                  :badge="false"
                  :toggle="true"
                  :showStrengthMeter="false"
                >
                </Password>
                <div v-if="showMismatchAlert" class="password-alert">
                  <v-icon color="red" size="15">mdi-alert-circle</v-icon>
                  <span>Passwords must match</span>
                </div>
                <div class="mt-3">
                  <v-btn
                    @click="createAccount"
                    class="d-none d-sm-block white--text rounded-md text-capitalize"
                    dark
                    dense
                    :loading="isCreating"
                    width="100%"
                    height="40px"
                    color="black"
                  >
                    Create account
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-dialog v-model="alertDialog" persistent max-width="430">
      <v-card class="pa-6 outfit-font-family">
        <div
          class="mb-2 pl-0 text-center font-weight-bold"
          style="font-size: 30px"
        >
          {{ inviteDescription }}
        </div>
        <v-card-actions class="pa-0">
          <v-btn
            class="white--text text-capitalize rounded-md font-weight-medium letter-spacing-08"
            dense
            width="100%"
            dark
            color="black"
            @click="
              dialog = false;
              goToHome();
            "
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="responseModal" persistent max-width="600">
      <v-card class="pa-6 outfit-font-family">
        <div
          class="mb-2 pl-0 text-center font-weight-bold"
          style="font-size: 30px"
        >
          {{ getCreateAccountDescription }}
        </div>
        <v-card-actions class="pa-0">
          <v-btn
            class="white--text text-capitalize rounded-md font-weight-medium letter-spacing-08"
            dense
            width="100%"
            dark
            color="black"
            @click="
              dialog = false;
              goToHome();
            "
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped lang="scss">
.setpassword-styles {
  @import "../assets/styles/ForgotPassword.scss";
  @import "../assets/styles/SetPassword.scss";
  font-family: "Outfit", sans-serif;

  .inter-font-family {
    font-family: "Inter", sans-serif;
  }

  .outfit-font-family {
    font-family: "Outfit", sans-serif;
  }
}
</style>

<script>
import Password from "vue-password-strength-meter";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ForgotPassword",

  components: {
    Password,
  },

  data: () => ({
    password: null,
    password_confirmation: null,
    score: 0,
    inviteToken: null,
    alertDialog: false,
    isLoading: true,
    inviteDescription: "",
    feedback: null,
    isCreating: false,
  }),
  computed: {
    ...mapGetters(["getCreateAccountDescription", "getIsAccountCreated"]),
    responseModal() {
      return this.getIsAccountCreated != null;
    },
    isInviteSuccess() {
      return this.getInviteSuccess;
    },
    strength() {
      return ["Very Weak", "Weak", "Moderate", "Strong", "Very Strong"][
        this.score
      ];
    },
    strengthStyle() {
      return [
        "very-weak-color",
        "weak-color",
        "moderate-color",
        "strong-color",
        "very-strong-color",
      ][this.score];
    },
    showMismatchAlert() {
      return (
        this.password &&
        this.password_confirmation &&
        this.password != this.password_confirmation
      );
    },
    passwordAlertDescription() {
      if (!this.password) {
        return "Password is required";
      }
      return this.validatePassword(this.password)
        ? ""
        : "Password does not contain a symbol";
    },
    showPasswordAlert() {
      console.log(this.password);

      console.log(this.validatePassword(this.password));

      return !this.password || !this.validatePassword(this.password);
    },
  },
  methods: {
    ...mapActions(["consumeInvite"]),
    validatePassword(password) {
      const symbols = "!@#$%^&*()_+-=[]{}|;:'\",.<>/?";

      for (let i = 0; i < password?.length; i++) {
        if (symbols.includes(password[i])) {
          return true;
        }
      }
      return false;
    },
    setScore(score) {
      this.score = score;
    },
    setFeedBack(feedback) {
      this.feedback = feedback;
    },
    async createAccount() {
      if (this.showMismatchAlert || this.showPasswordAlert) {
        return;
      }
      if (this.feedback.suggestions.length) {
        this.$toast.warning(this.feedback.suggestions[0], {});
        return;
      }
      const request = {
        invite: this.inviteToken,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      try {
        this.isCreating = true;
        await this.consumeInvite(request);
      } finally {
        this.isCreating = false;
      }
    },
    goToHome() {
      this.$router.push("/signin");
    },
  },
  mounted() {
    document.title = "Carrier Portal - Breaker19.app";
  },
  created() {
    this.inviteToken = this.$route.query.invite;
    if (!this.inviteToken) {
      this.alertDialog = true;
      this.inviteDescription = "Token not found";
      return;
    }
    const request = {
      invite: this.inviteToken,
    };
    this.$api
      .post("/carriers/invite/consume", request)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success == true) {
            this.alertDialog = true;
            this.inviteDescription = "Invite accepted successfully";
          }
          this.isLoading = false;
        }
      })
      .catch(() => {
        this.alertDialog = true;
        this.inviteDescription = "Invalid token";
        this.isLoading = false;
      });
  },
};
</script>
