import Vue from "vue";
import VueRouter from "vue-router";

import SignIn from "./views/SignIn.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import AddAuthorizedUser from "./views/AddAuthorizedUser.vue";
import RegionList from "./views/RegionList.vue";
import AddDriver from "./views/AddDrivers.vue";
import EditDriver from "./views/EditDriver.vue";
import DriversList from "./views/DriversList.vue";
import AuthorizedUsersList from "./views/AuthorizedUsersList.vue";
import ConfigureRegionRates from "./views/ConfigureRegionRates.vue";
import DiscountsList from "./views/DiscountsList.vue";
import ShipmentList from "./views/ShipmentList.vue";
import ShipmentDetails from "./views/ShipmentDetails.vue";
import Homepage from "./views/Homepage.vue";
import InviteUser from "./views/InviteUser.vue";
import ManageProfile from "./views/ManageProfile.vue";
import PageNotFound from "./views/PageNotFound.vue";
import router from "@/router";

Vue.use(VueRouter);

const routes = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/signin",
      name: "signin",
      component: SignIn,
    },
    {
      path: "/forgot-password",
      name: "forgot",
      component: ForgotPassword,
    },
    {
      path: "/add-authorized-user",
      name: "addAuthorizedUser",
      component: AddAuthorizedUser,
    },
    {
      path: "/authorized-users-list",
      name: "authorizedUsersList",
      component: AuthorizedUsersList,
    },
    {
      path: "/region-list",
      name: "regionList",
      component: RegionList,
    },
    {
      path: "/discounts-list",
      name: "discountsList",
      component: DiscountsList,
    },
    {
      path: "/drivers-list",
      name: "driversList",
      component: DriversList,
    },
    {
      path: "/add-driver/:previousPage/:tabNumber/:shipmentID",
      name: "addDriver",
      component: AddDriver,
    },
    {
      path: "/edit-driver/:id",
      name: "editDriver",
      component: EditDriver,
    },
    {
      path: "/configure-regions/:regionId/:regionName",
      name: "configureRegionRates",
      component: ConfigureRegionRates,
    },
    {
      path: "/shipment-list",
      name: "ShipmentList",
      component: ShipmentList,
    },
    {
      path: "/shipment-details/:previousPage/:selectedTabNumber/:shipmentID",
      name: "shipmentDetails",
      component: ShipmentDetails,
    },
    {
      path: "/manage-profile",
      name: "ManageProfile",
      component: ManageProfile,
    },
    {
      path: "/homepage",
      name: "Homepage",
      component: Homepage,
    },
    {
      path: "/invite",
      name: "invite",
      component: InviteUser,
    },
    {
      path: "*",
      name: "PageNotFound",
      component: PageNotFound,
    },
    // {
    //   path: "*",
    //   name: "404",
    //   component: SignIn,
    // }
  ],
});

routes.beforeEach(async (to, _, next) => {
  let adminToken = to.query["admin-token"];
  let impersonationToken = to.query["impersonation-token"];

  if (adminToken && impersonationToken) {
    localStorage.setItem("impersonationToken", impersonationToken);
    localStorage.setItem("token", adminToken);
  }

  const authorizedRoutes = [
    "addAuthorizedUser",
    "authorizedUsersList",
    "driversList",
    "addDriver",
    "configureRegionRates",
    "regionList",
    "Homepage",
    "InviteUser",
    "ShipmentList",
    "shipmentDetails",
    "ManageProfile",
    "editDriver",
    "PageNotFound",
    "discountsList",
  ];
  if (to.name != "404" && localStorage.isAuthorized === "true") {
    if (authorizedRoutes.includes(to.name) && to.path != "/") next();
    else router.push("/homepage");
  } else {
    if (
      to.name === "signin" ||
      to.name === "forgot" ||
      to.name === "invite" ||
      (adminToken && impersonationToken)
    )
      next();
    else router.push("/signin");
  }

  next();
});

export default routes;
