import axios from "axios";

export const createAxiosInstance = () => {
  // Create axios instance with base configuration
  const axiosInstance = axios.create({
    baseURL:
      localStorage.getItem("apiBaseURL") || process.env.VUE_APP_API_BASE_URL,
    timeout: 30000,
  });

  // Save base URL for future reference
  localStorage.setItem("apiBaseURL", axiosInstance.defaults.baseURL);

  // Set authorization headers if tokens exist
  const accessToken = localStorage.getItem("authToken");
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = accessToken;
  }

  // Set impersonation header if token exists
  const impersonationToken = localStorage.getItem("impersonationToken");
  if (impersonationToken) {
    axiosInstance.defaults.headers.common["X-Impersonate"] = impersonationToken;
  }

  return axiosInstance;
};
