export class ShipmentStatus {
    // Quote (Pending shipment)
    QUOTE_ACTIVE = 0;
    QUOTE_INACTIVE = 18;

    // Plan (Pending shipment)
    TENDERED = 1;
    COVERED = 2;

    // Tender (Pending shipment [except: TENDER_REJECTED])
    TENDER_OFFERED = 15;
    TENDER_ACCEPTED = 16;
    TENDER_REJECTED = 17;

    // Ship (Processing or active shipment [except: DELIVERED])
    DISPATCHED = 3;
    AT_PICKUP = 4;
    EN_ROUTE = 5;
    AT_DELIVERY = 6;
    ROUTE_COMPLETE = 7;
    DELIVERED = 8;
    PICKED_UP = 19;

    // Bill
    READY_FOR_BILLING = 9;
    PROCESSING = 10;    // Pending
    CARRIER_PAID = 11;
    CUSTOMER_PAID = 12;
    COMPLETED = 13;     // Completed
    CANCELED = 14;
}