<template>
  <v-row style="background-color: #f1f1f1">
    <v-col style="background-color: #f1f1f1">
      <div class="discountsList-styles">
        <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

        <div class="discounts-contents">
          <div>
            <p class="title1">
              {{ carrierName }}
            </p>
            <p class="title2">Discounts</p>
          </div>
          <div class="discounts-list">
            <div class="discounts-container">
              <template v-for="item of discountsList">
                <div class="grid-item discount-item" :key="item.id">
                  <div class="buyer-text">
                    {{ item.name }}
                  </div>
                  <div class="buyer-text">
                    {{ item?.discounts?.[0]?.rate }}%
                  </div>
                </div>
              </template>

              <template v-if="isLoading">
                <template v-for="n in 3">
                  <div class="grid-item" :key="n">
                    <div class="discounts-list">

                      <div class="discount-item">
                        <template>
                          <v-skeleton-loader
                            class="skeleton-remove-padding skeleton-full-width skeleton-full-width_btn"
                            type="button"
                          ></v-skeleton-loader>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>

            <p style="margin-top: 100px; font-size: 16px; max-width: 630px">
              If you would like discounts applied any Buyers, please contact
              dispatch@breaker19.app and our team can update these
              discounts.
            </p>
          </div>
        </div>
      </div>
    </v-col>

    <!-- <AppLoading :active.sync="isLoading" /> -->
  </v-row>
</template>

<style scoped lang="scss">
.discountsList-styles {
  @import "../assets/styles/DiscountsList.scss";

  background-color: #f4f4f4;
  font-family: "Outfit", sans-serif;

  .inter-font-family {
    font-family: "Inter", sans-serif !important;
  }

  .outfit-font-family {
    font-family: "Outfit", sans-serif !important;
  }
}
</style>

<script>

import mixin from "../mixins/auth.mixin.js";

import MobileHeader from "../components/mobile-header.vue";

export default {
  name: "DiscountsList",
  components: {
    MobileHeader,
  },
  data: () => ({
    authMixins: mixin.methods,
    windowWidth: 0,
    isLoading: true,
    requestStatus: false,
    discountsList: [],
    errorStatus: "",
    carrierName: "",
  }),
  computed: {
    localStorage() {
      return localStorage;
    },
  },
  created() {
    this.updateWindowWidth();
    this.carrierName = localStorage.getItem("name");
    window.addEventListener("resize", this.updateWindowWidth);
  },
  mounted() {
    document.title = "Carrier Portal - Breaker19.app";

    this.loadDiscountsList();
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    loadDiscountsList() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      this.$api.get(`carriers/discounts`, config)
        .then((response) => {
          if (response.status === 200) {
            this.discountsList = response.data;
          }
          if (response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push("/signin");
          }
          this.isLoading = false;
        })
        .catch(async (err) => {
          this.isLoading = false;

          if (err.response && err.response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push("/signin");
          }

          console.log("err: ", err);
        });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
};
</script>
