<template>
  <div class="shipmentList-styles">
    <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

    <div class="main-content">
      <div class="middle-content">
        <div
          style="background-color: #fff; border-radius: 10px"
          class="pt-7 pb-5 pl-5 pr-5"
        >
          <div class="justify-space-between">
            <div>
              <h5 class="theme-text-color auth-user-title1">
                {{ localStorage.username }}
              </h5>
              <h3 class="theme-text-color auth-user-title2">Shipments</h3>
            </div>
            <div
              class="shipment-status d-flex justify-space-between align-center"
            >
              <div
                @click="selectTab(10)"
                class="tab"
                :class="{ selectedTab: selectedTabNumber === 10 }"
              >
                <p large class="add-driver-btn" width="100%">
                  Pending
                  {{
                    totals[1] !== undefined
                      ? `(${totals[1]})`
                      : isTotalLoading
                      ? ""
                      : "(0)"
                  }}
                </p>
              </div>

              <div
                @click="selectTab(0)"
                class="tab"
                :class="{ selectedTab: selectedTabNumber === 0 }"
              >
                <p large class="add-driver-btn" width="100%">
                  Active
                  {{
                    totals[0] !== undefined
                      ? `(${totals[0]})`
                      : isTotalLoading
                      ? ""
                      : "(0)"
                  }}
                </p>
              </div>

              <div
                @click="selectTab(13)"
                class="tab"
                :class="{ selectedTab: selectedTabNumber === 13 }"
              >
                <p large class="add-driver-btn" width="100%">
                  Completed
                  {{
                    totals[2] !== undefined
                      ? `(${totals[2]})`
                      : isTotalLoading
                      ? ""
                      : "(0)"
                  }}
                </p>
              </div>
            </div>
          </div>

          <div
            :class="{
              'tablet-mobile-grid-view':
                shipmentList && shipmentList.length !== 0 && windowWidth < 1264,
            }"
            v-if="selectedTabNumber === 10"
          >
            <template v-for="(shipment, s) of shipmentList">
              <v-row
                :key="shipment.turvo_id"
                :class="{ 'pr-4': windowWidth < 1264 }"
                class="section-card mt-6 no-gutters"
              >
                <div
                  class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                >
                  <div
                    class="item-list-info-pending info-mobile-view d-flex flex-column align-self-center"
                  >
                    <p class="mini-titles">Status</p>
                    <div class="shipment-status-pending">Awaiting Response</div>
                  </div>

                  <div
                    class="item-list-info-pending d-flex flex-row-reverse flex-lg-row info-mobile-view justify-end"
                  >
                    <div class="d-flex flex-column ml-5 ml-lg-0">
                      <p class="mini-titles">Created Date</p>
                      <div class="mini-titles-val" v-if="shipment.created_at">
                        {{
                          formatDate(shipment.created_at).format("MM/DD/YYYY")
                        }}
                      </div>
                      <div class="mini-titles-val" v-if="!shipment.created_at">
                        -
                      </div>
                    </div>
                    <div class="d-flex flex-column ml-5 ml-lg-0">
                      <p class="mini-titles">Pickup Date</p>
                      <div class="mini-titles-val" v-if="shipment.pickup_time">
                        {{
                          formatDate(shipment.pickup_time).format("MM/DD/YYYY")
                        }}
                      </div>
                      <div class="mini-titles-val" v-if="!shipment.pickup_time">
                        As soon as possible
                      </div>
                    </div>

                    <div class="d-flex flex-column">
                      <p class="mini-titles">Pickup Time</p>
                      <div class="mini-titles-val" v-if="shipment.pickup_time">
                        {{
                          formatDate(shipment.pickup_time).format("h:mm a (z)")
                        }}
                      </div>
                      <div class="mini-titles-val" v-if="!shipment.pickup_time">
                        -
                      </div>
                    </div>
                  </div>
                  <div
                    class="item-list-info-pending d-flex justify-lg-center info-mobile-view ml-lg-5"
                  >
                    <div class="d-flex flex-column sub-section">
                      <p class="mini-titles">Pick Up</p>
                      <a
                        class="mini-titles-val"
                        :href="getLocationUrl(shipment.pickup_location)"
                        target="_blank"
                      >
                        {{
                          shipment?.pickup_location?.city +
                          ", " +
                          shipment?.pickup_location?.state
                        }}
                      </a>
                    </div>

                    <div class="d-flex align-center connection-arrow">
                      <v-img src="../assets/icons/forward_arrow.png"></v-img>
                    </div>

                    <div class="d-flex flex-column sub-section">
                      <p class="mini-titles">Drop Off</p>
                      <a
                        class="mini-titles-val"
                        :href="getLocationUrl(shipment.dropoff_location)"
                        target="_blank"
                      >
                        {{
                          shipment?.dropoff_location?.city +
                          ", " +
                          shipment?.dropoff_location?.state
                        }}
                      </a>
                    </div>
                  </div>

                  <div
                    class="item-list-info-pending d-flex info-mobile-view ml-lg-2 mb-4 mb-lg-0 justify-space-between align-center"
                  >
                    <div class="d-flex flex-column">
                      <p class="mini-titles">Type</p>
                      <div
                        class="mini-titles-val completed-shipment-type d-flex align-center"
                      >
                        <p class="mb-0">
                          {{ shipment.region_service?.value.name }}
                        </p>
                      </div>
                    </div>

                    <div class="truck-type-img">
                      <v-img
                        :src="shipment.region_service?.value.links.original"
                      ></v-img>
                    </div>
                    <!-- <div v-if="shipment.region_service?.value.links.original">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        max-height="40"
                        type="image"
                      ></v-skeleton-loader>
                    </div> -->

                    <div class="d-flex flex-column ml-8 ml-lg-0">
                      <p class="mini-titles">Price</p>
                      <div class="mini-titles-val">
                        {{
                          shipment?.quote_result?.carrier_cut
                            ? parseFloat(shipment?.quote_result?.carrier_cut).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : null
                        }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="item-list-info-pending info-mobile-view d-flex justify-space-between justify-lg-space-around ml-lg-3"
                  >
                    <div class="d-flex flex-column btn-dailog">
                      <v-btn
                        class="accept-btn info-btns white--text green"
                        dark
                        block
                        color="black"
                        @click="
                          acceptOrderDialog = true;
                          selectShipment(shipment.id, shipment, s);
                        "
                      >
                        Accept
                      </v-btn>
                    </div>

                    <div class="d-flex flex-column btn-dailog">
                      <div class="reject-btn">
                        <v-btn
                          @click="
                            rejectOrderDialog = true;
                            selectShipment(shipment.id, shipment, s);
                          "
                          class="reject-btn info-btns red--text"
                          outlined
                        >
                          Reject
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <div
                    class="item-list-info-pending info-mobile-view d-flex flex-column align-end align-lg-center mt-5 mt-lg-0"
                  >
                    <router-link
                      :to="{
                        path: `/shipment-details/shiplist/${selectedTabNumber}/${shipment.id}`,
                      }"
                    >
                      See Details
                    </router-link>
                  </div>
                </div>
              </v-row>
            </template>

            <h3
              class="no-shipment-msg"
              v-if="
                !requestStatus &&
                !request['pending'] &&
                shipmentList.length === 0
              "
            >
              No pending shipments
            </h3>

            <br />
          </div>

          <div
            :class="{ 'tablet-mobile-grid-view': windowWidth < 1264 }"
            v-if="selectedTabNumber === 10 && request['pending']"
          >
            <template v-for="n in 3">
              <v-row
                :key="n"
                :class="{ 'pr-4': windowWidth < 1264 }"
                class="section-card mt-6 no-gutters"
              >
                <div
                  class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                >
                  <div
                    class="item-list-info-pending info-mobile-view d-flex flex-column align-self-center"
                  >
                    <p class="mini-titles">Status</p>
                    <div class="shipment-status-pending">
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-pending d-flex flex-row-reverse flex-lg-row info-mobile-view justify-end justify-lg-space-around"
                  >
                    <div class="d-flex flex-column skeleton-mini-width">
                      <p class="mini-titles">Created Date</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                    <div
                      class="d-flex flex-column ml-5 ml-lg-0 skeleton-mini-width"
                    >
                      <p class="mini-titles">Pickup Date</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="d-flex flex-column skeleton-mini-width">
                      <p class="mini-titles">Pickup Time</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-pending d-flex info-mobile-view ml-lg-10"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Pick Up</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="d-flex align-center connection-arrow">
                      <v-img src="../assets/icons/forward_arrow.png"></v-img>
                    </div>

                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Drop Off</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="skeleton-mini-width item-list-info-pending d-flex info-mobile-view mb-4 mb-lg-0 justify-space-between align-center"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Type</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="truck-type-img">
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-height="40"
                        max-width="90"
                        type="image"
                      ></v-skeleton-loader>
                    </div>

                    <div
                      class="d-flex flex-column ml-8 ml-lg-3 skeleton-smallest-width"
                    >
                      <p class="mini-titles">Price</p>
                      <v-skeleton-loader
                        max-width="90"
                        class="skeleton-remove-padding skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-pending info-mobile-view d-flex justify-space-between justify-lg-space-around ml-lg-3"
                  >
                    <div class="d-flex flex-column btn-dailog">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        max-width="90"
                        type="button"
                      ></v-skeleton-loader>
                    </div>

                    <div class="d-flex flex-column btn-dailog">
                      <v-skeleton-loader
                        max-width="90"
                        class="skeleton-full-width"
                        type="button"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-pending info-mobile-view d-flex flex-column align-end align-lg-center mt-5 mt-lg-0"
                  >
                    <v-skeleton-loader
                      max-width="90"
                      class="skeleton-full-width"
                      type="list-item"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-row>
            </template>
          </div>

          <!-- ##################################################################### -->
          <!-- ################################################################## -->
          <!-- ##################################################################### -->

          <div
            :class="{
              'tablet-mobile-grid-view':
                shipmentList && shipmentList.length !== 0 && windowWidth < 1264,
            }"
            v-if="selectedTabNumber === 0"
          >
            <template v-for="shipment of shipmentList">
              <v-row
                :key="shipment.id"
                :class="{ 'pr-4': windowWidth < 1264 }"
                class="section-card mt-6 no-gutters"
              >
                <div
                  class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                >
                  <div
                    class="item-list-info-active info-mobile-view d-flex align-self-center justify-start justify-lg-space-between"
                  >
                    <div class="d-flex flex-column">
                      <p class="mini-titles">Status</p>
                      <div class="shipment-status-active">Active</div>
                    </div>

                    <div class="d-flex flex-column ml-8 ml-lg-0">
                      <p class="mini-titles">Created Date</p>
                      <div
                        v-if="shipment.created_at"
                        class="mini-titles-val active-pickup-date"
                      >
                        {{
                          formatDate(shipment.created_at).format("MM/DD/YYYY")
                        }}
                      </div>
                      <div
                        v-if="!shipment.created_at"
                        class="mini-titles-val active-pickup-date"
                      >
                        -
                      </div>
                    </div>

                    <div class="d-flex flex-column ml-8 ml-lg-0">
                      <p class="mini-titles">Pickup Date</p>
                      <div
                        v-if="shipment.pickup_time != null"
                        class="mini-titles-val active-pickup-date"
                      >
                        {{
                          formatDate(shipment.pickup_time).format("MM/DD/YYYY")
                        }}
                      </div>
                      <div
                        v-if="shipment.pickup_time == null"
                        class="mini-titles-val active-pickup-date"
                      >
                        As soon as possible
                      </div>
                    </div>
                  </div>

                  <div
                    class="item-list-info-active d-flex info-mobile-view ml-lg-12"
                  >
                    <div class="d-flex flex-column">
                      <p class="mini-titles">Pick Up</p>
                      <a
                        class="mini-titles-val"
                        :href="getLocationUrl(shipment.pickup_location)"
                        target="_blank"
                      >
                        {{
                          shipment.pickup_location?.city +
                          ", " +
                          shipment.pickup_location?.state
                        }}
                      </a>
                    </div>

                    <div class="d-flex align-center connection-arrow">
                      <v-img src="../assets/icons/forward_arrow.png"></v-img>
                    </div>

                    <div class="d-flex flex-column">
                      <p class="mini-titles">Drop Off</p>
                      <a
                        class="mini-titles-val"
                        :href="getLocationUrl(shipment.dropoff_location)"
                        target="_blank"
                      >
                        {{
                          shipment?.dropoff_location?.city +
                          ", " +
                          shipment?.dropoff_location?.state
                        }}
                      </a>
                    </div>
                  </div>

                  <div
                    class="item-list-info-active d-flex info-mobile-view align-center justify-space-between"
                  >
                    <div class="d-flex flex-column">
                      <p class="mini-titles mb-0">Type</p>
                      <div class="mini-titles-val completed-shipment-type">
                        <p class="mb-0">
                          {{ shipment.region_service?.value.name }}
                        </p>
                      </div>
                    </div>

                    <div class="truck-type-img" v-if="windowWidth > 1264">
                      <v-img
                        :src="shipment.region_service?.value.links.original"
                      ></v-img>
                    </div>

                    <div class="d-flex flex-column ml-8 ml-lg-0">
                      <p class="mini-titles">Driver</p>
                      <div class="mini-titles-val">
                        {{
                          shipment.driver
                            ? shipment.driver.name
                            : "Not assigned"
                        }}
                      </div>
                    </div>

                    <div class="d-flex flex-column ml-8 ml-lg-0">
                      <p class="mini-titles">Price</p>
                      <div class="mini-titles-val">
                        {{
                          shipment?.quote_result?.carrier_cut
                            ? parseFloat(shipment?.quote_result?.carrier_cut).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : null
                        }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="item-list-info-active info-mobile-view mt-4 mt-lg-0 d-flex justify-space-between"
                  >
                    <div
                      class="d-flex flex-column justify-end justify-lg-center mb-1 mb-lg-0"
                    >
                      <router-link
                        :to="{
                          path: `/shipment-details/shiplist/${selectedTabNumber}/${shipment.id}`,
                        }"
                      >
                        See Details
                      </router-link>
                    </div>

                    <div class="truck-type-img" v-if="windowWidth <= 1264">
                      <v-img
                        :src="shipment.region_service?.value.links.original"
                      ></v-img>
                    </div>
                  </div>
                </div>
              </v-row>
            </template>

            <h3
              class="no-shipment-msg"
              v-if="
                !requestStatus &&
                !request['active'] &&
                shipmentList.length === 0
              "
            >
              No active shipments
            </h3>

            <br />
          </div>

          <div
            :class="{ 'tablet-mobile-grid-view': windowWidth < 1264 }"
            v-if="selectedTabNumber === 0 && request['active']"
          >
            <template v-for="n in 3">
              <v-row
                :key="n"
                :class="{ 'pr-4': windowWidth < 1264 }"
                class="section-card mt-6 no-gutters"
              >
                <div
                  class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                >
                  <div
                    class="item-list-info-active info-mobile-view d-flex align-self-center justify-start justify-lg-space-between"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Status</p>
                      <div class="shipment-status-active">
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="d-flex flex-column ml-8 ml-lg-0 skeleton-smallest-width"
                    >
                      <p class="mini-titles">Created Date</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div
                      class="d-flex flex-column ml-8 ml-lg-0 skeleton-smallest-width"
                    >
                      <p class="mini-titles">Pickup Date</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-active d-flex info-mobile-view ml-lg-12"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Pick Up</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="d-flex align-center connection-arrow">
                      <v-img src="../assets/icons/forward_arrow.png"></v-img>
                    </div>

                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Drop Off</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-active d-flex info-mobile-view align-center justify-space-between"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles mb-0">Type</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="truck-type-img" v-if="windowWidth > 1264">
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-height="40"
                        max-width="90"
                        type="image"
                      ></v-skeleton-loader>
                    </div>

                    <div
                      class="d-flex flex-column ml-8 ml-lg-3 skeleton-smallest-width"
                    >
                      <p class="mini-titles">Driver</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div
                      class="d-flex flex-column ml-8 ml-lg-0 skeleton-smallest-width"
                    >
                      <p class="mini-titles">Price</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-active info-mobile-view mt-4 mt-lg-0 d-flex justify-space-between justify-lg-space-around"
                  >
                    <v-skeleton-loader
                      class="skeleton-remove-padding skeleton-full-width"
                      max-width="90"
                      type="list-item"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-row>
            </template>
          </div>

          <!-- ##################################################################### -->
          <!-- ################################################################## -->
          <!-- ##################################################################### -->

          <div
            :class="{
              'tablet-mobile-grid-view':
                shipmentList && shipmentList.length !== 0 && windowWidth < 1264,
            }"
            v-if="selectedTabNumber === 13"
          >
            <template v-for="shipment of shipmentList">
              <v-row
                :key="shipment.id"
                :class="{ 'pr-4': windowWidth < 1264 }"
                class="section-card mt-6 no-gutters"
              >
                <div
                  class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                >
                  <div
                    class="item-list-info-completed info-mobile-view d-flex align-self-center justify-space-between"
                  >
                    <div class="d-flex flex-column">
                      <p class="mini-titles">Created Date</p>

                      <div
                        v-if="shipment.created_at != null"
                        class="mini-titles-val completed-pickup-date"
                      >
                        {{
                          formatDate(shipment.created_at).format("MM/DD/YYYY")
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column ml-8 ml-lg-10">
                      <p class="mini-titles">Pickup Date</p>

                      <div
                        v-if="shipment.pickup_time != null"
                        class="mini-titles-val completed-pickup-date"
                      >
                        {{
                          formatDate(shipment.pickup_time).format("MM/DD/YYYY")
                        }}
                      </div>
                      <div
                        v-if="shipment.pickup_time == null"
                        class="mini-titles-val completed-pickup-date"
                      >
                        As soon as possible
                      </div>
                    </div>

                    <div class="d-flex flex-column ml-8 ml-lg-10">
                      <p class="mini-titles">Type</p>
                      <div class="mini-titles-val completed-shipment-type">
                        <p class="mb-0">
                          {{ shipment.region_service?.value.name }}
                        </p>
                      </div>
                    </div>

                    <div class="truck-type-img">
                      <v-img
                        :src="shipment.region_service?.value.links.original"
                      ></v-img>
                    </div>
                  </div>

                  <div
                    class="item-list-info-completed d-flex info-mobile-view ml-lg-7"
                  >
                    <div class="d-flex flex-column">
                      <p class="mini-titles">Pick Up</p>
                      <a
                        class="mini-titles-val"
                        :href="getLocationUrl(shipment.pickup_location)"
                        target="_blank"
                      >
                        {{
                          shipment.pickup_location?.city +
                          ", " +
                          shipment.pickup_location?.state
                        }}
                      </a>
                    </div>

                    <div class="d-flex align-center connection-arrow">
                      <v-img src="../assets/icons/forward_arrow.png"></v-img>
                    </div>

                    <div class="d-flex flex-column">
                      <p class="mini-titles">Drop Off</p>
                      <a
                        class="mini-titles-val"
                        :href="getLocationUrl(shipment.dropoff_location)"
                        target="_blank"
                      >
                        {{
                          shipment.dropoff_location?.city +
                          ", " +
                          shipment.dropoff_location?.state
                        }}
                      </a>
                    </div>
                  </div>

                  <div
                    class="item-list-info-completed d-flex info-mobile-view justify-lg-space-around mb-4 mb-lg-0"
                  >
                    <div class="d-flex flex-column">
                      <p class="mini-titles">Driver</p>
                      <div class="mini-titles-val">
                        {{
                          shipment.driver
                            ? shipment.driver.name
                            : "Not assigned"
                        }}
                      </div>
                    </div>

                    <div class="d-flex flex-column ml-8 ml-lg-0">
                      <p class="mini-titles">Price</p>
                      <div class="mini-titles-val">
                        {{
                          shipment?.quote_result?.carrier_cut
                            ? parseFloat(shipment?.quote_result?.carrier_cut).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : null
                        }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="item-list-info-completed info-mobile-view d-flex justify-space-between justify-lg-space-around"
                  >
                    <div
                      v-if="!shipment.cancel_time"
                      class="d-flex flex-column"
                    >
                      <p class="mini-titles">Payment</p>
                      <div class="d-flex align-center">
                        <v-icon
                          v-if="
                            shipment.status > ShipmentStatus.CARRIER_PAID &&
                            shipment.status !== ShipmentStatus.CANCELED
                          "
                          class="pl-1"
                          color="#3AB03D"
                          >mdi-check
                        </v-icon>
                      </div>
                    </div>
                    <div v-else class="d-flex flex-column">
                      <p class="mini-titles canceled">Canceled</p>
                    </div>

                    <div
                      class="d-flex flex-column justify-end justify-lg-center mb-1 mb-lg-0"
                    >
                      <router-link
                        :to="{
                          path: `/shipment-details/shiplist/${selectedTabNumber}/${shipment.id}`,
                        }"
                      >
                        See Details
                      </router-link>
                    </div>
                  </div>
                </div>
              </v-row>
            </template>

            <h3
              class="no-shipment-msg"
              v-if="
                !requestStatus &&
                shipmentList.length === 0 &&
                !request['completed']
              "
            >
              No completed shipments
            </h3>

            <br />
          </div>

          <div
            :class="{ 'tablet-mobile-grid-view': windowWidth < 1264 }"
            v-if="selectedTabNumber === 13 && request['completed']"
          >
            <template v-for="n in 3">
              <v-row
                :key="n"
                :class="{ 'pr-4': windowWidth < 1264 }"
                class="section-card mt-6 no-gutters"
              >
                <div
                  class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                >
                  <div
                    class="item-list-info-completed info-mobile-view d-flex align-self-center justify-space-between"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Created Date</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Pickup Date</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div
                      class="d-flex flex-column ml-8 ml-lg-10 skeleton-smallest-width"
                    >
                      <p class="mini-titles">Type</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="truck-type-img">
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-height="40"
                        max-width="90"
                        type="image"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-completed d-flex info-mobile-view ml-lg-7"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Pick Up</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div class="d-flex align-center connection-arrow">
                      <v-img src="../assets/icons/forward_arrow.png"></v-img>
                    </div>

                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Drop Off</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-completed d-flex info-mobile-view justify-lg-space-around mb-4 mb-lg-0"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Driver</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div
                      class="d-flex flex-column ml-8 ml-lg-0 skeleton-smallest-width"
                    >
                      <p class="mini-titles">Price</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>

                  <div
                    class="item-list-info-completed info-mobile-view d-flex justify-space-between justify-lg-space-around"
                  >
                    <div class="d-flex flex-column skeleton-smallest-width">
                      <p class="mini-titles">Payment</p>
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>

                    <div
                      class="d-flex flex-column justify-end justify-lg-center mb-1 mb-lg-0 skeleton-smallest-width"
                    >
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                </div>
              </v-row>
            </template>
          </div>
          <!-- ######################################################################## -->
        </div>
      </div>
    </div>

    <v-dialog v-model="rejectOrderDialog" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="reject-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Are you sure you want to reject this order?
        </v-card-title>
        <div
          class="title-info d-flex align-center align-sm-start align-start mb-3 mt-3 pl-0"
        >
          <img width="17px" src="../assets/icons/info-icon.png" />
          <span class="pl-1 accept-order-info">
            You won’t be able to revisit this order again</span
          >
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="rejectOrderDialog = false"
          >
            Back to list
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              rejectOrderDialog = false;
              orderAccept(false);
            "
          >
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="acceptOrderDialog" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="accept-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Accept This Order?
        </v-card-title>
        <div class="title-info d-flex align-start mb-2 pl-0">
          <img width="17px" src="../assets/icons/info-icon.png" />
          <span class="pl-1 font-weight-bold accept-order-info">
            You must assign a driver before you can accept an order. You must
            have added your driver in the
            <a href="#" class="color-black">drivers tab</a> for them to appear
            as an option. Please note that by accepting this shipment, you are
            agreeing to the rate shown.
          </span>
        </div>

        <div>
          <span class="accept-order">Choose driver</span>
          <v-select
            :items="driversList"
            v-model="selectedDriver"
            item-value="id"
            item-text="name"
            color="grey"
            class="accept-order-vselect"
            background-color="#F4F4F4"
            label="Select a driver"
            append-icon="mdi-chevron-down-box"
            height="10px"
            return-object
            solo
            dense
            flat
          >
          </v-select>
        </div>

        <div class="active-order-price mb-2">
          <p class="mb-0">Price (what you will make)</p>
          <p class="mb-0">
            {{
              selectedShipment?.quote_result?.carrier_cut
                ? parseFloat(selectedShipment?.quote_result?.carrier_cut).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : ""
            }}
          </p>
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="acceptOrderDialog = false"
          >
            Back to list
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="orderAccept(true)"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <AppLoading :active.sync="isLoading" /> -->
  </div>
</template>

<style scoped lang="scss">
.shipmentList-styles {
  @import "../assets/styles/ShipmentList.scss";

  font-family: "Outfit", sans-serif;
}
</style>

<script>
import axios from 'axios';
import { convertTimezone } from "@/helpers/timeHelper.js";
import { ShipmentStatus } from "@/models/shipment_status";


import MobileHeader from "../components/mobile-header.vue";
import mixin from "../mixins/auth.mixin.js";
import moment from "moment";

export default {
  path: "ShipmentList",
  components: {
    MobileHeader,
  },
  data: () => ({
    authMixins: mixin.methods,
    isLoading: false,
    acceptOrderDialog: false,
    rejectOrderDialog: false,
    requestStatus: false,
    selectedTabNumber: 10,
    windowWidth: 0,
    selectedShipmentId: null,
    selectedShipmentIndex: -1,
    selectedShipment: null,
    totals: [],
    shipmentList: [],
    maxPages: 1,
    driversList: [],
    cancelFetchData: null,
    selectedDriver: null,
    isLoadingNewPage: false,
    page: 1,
    isTotalLoading: false,
    request: {
      active: false,
      pending: false,
      completed: false,
    },
  }),

  computed: {
    ShipmentStatus() {
      return ShipmentStatus;
    },
    localStorage() {
      return localStorage;
    },
  },

  watch: {
    page() {
      this.loadShipments(this.selectedTabNumber);
    },
  },
  created() {
    this.updateWindowWidth();
    this.getTotals();

    window.addEventListener("resize", this.updateWindowWidth);
    document.addEventListener("scroll", this.scrollWatcher);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
    document.removeEventListener("scroll", this.scrollWatcher);
  },

  mounted() {
    document.title = "Carrier Portal - Breaker19.app";

    if (localStorage.shipmentTabNo)
      this.selectedTabNumber = +localStorage.shipmentTabNo;

    this.loadShipments(this.selectedTabNumber);
    this.loadDrivers();
  },

  filters: {
    split_using_space: function (value, returnType) {
      if (!value) return "";
      value = value.split(" ");
      if (returnType === "date") {
        let splitted_date = value[0].split("-");
        let reformat =
          splitted_date[1] + "/" + splitted_date[2] + "/" + splitted_date[0];
        return reformat;
      } else if (returnType === "time") return value[1];
      else return value[0] + " " + value[1];
    },

    toTimezone(time) {
      if (!time) return;
      return convertTimezone(time, localStorage.timezone);
    },
  },

  methods: {
    formatDate(date) {
      let tz = "Etc/GMT";

      if (localStorage.timezone) {
        tz = localStorage.timezone;
      }

      return moment(date).tz(tz);
    },
    getTotals() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };
      let promises = [];
      promises.push(
        this.$api.get("carriers/shipments?status=active&page=1", config)
          .then(({ data }) => {
            this.totals[0] = data.total;
          })
      );

      promises.push(
        this.$api.get("carriers/shipments?status=pending&page=1", config)
          .then(({ data }) => {
            this.totals[1] = data.total;
          })
      );

      promises.push(
        this.$api.get("carriers/shipments?status=completed&page=1", config)
          .then(({ data }) => {
            this.totals[2] = data.total;
          })
      );
      this.isTotalLoading = true;

      Promise.all(promises)
        .then(() => {
          this.isTotalLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching totals:", error);
          this.isTotalLoading = false;
        });
    },
    scrollWatcher(event) {
      if (this.isLoadingNewPage) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } =
        event.target.scrollingElement;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        this.page = Math.min(this.maxPages, this.page + 1);
      }
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    // 10: pending, 0: active, 13: completed
    selectTab(tabNumber) {
      if (this.selectedTabNumber !== tabNumber) {
        this.requestStatus = false;
        this.cancelFetchData("User cancelled the fetch");
        this.shipmentList = [];

        this.selectedTabNumber = tabNumber;
        localStorage.shipmentTabNo = tabNumber;
        this.page = 1;
        this.loadShipments(tabNumber);
      }
    },

    selectShipment(shipmentId, shipmentData, shipmendIndex) {
      this.selectedShipmentId = shipmentId;
      this.selectedShipment = shipmentData;
      this.selectedShipmentIndex = shipmendIndex;
      this.selectedDriver = shipmentData.driver;
    },

    selectDriver(selectedDriver) {
      this.selectedDriver = selectedDriver;
    },

    loadShipments(statusID) {
      if (!this.requestStatus) {
        this.isLoadingNewPage = true;
        this.isLoading = true;
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelFetchData = source.cancel;

        this.requestStatus = true;

        let config = {
          headers: {
            Authorization: localStorage.authToken,
          },
          cancelToken: source.token,
        };

        let statusName = "";
        if (statusID === 0) statusName = "active";
        else if (statusID === 10) statusName = "pending";
        else if (statusID === 13) statusName = "completed";
        this.request[statusName] = true;

        this.$api.get(
            `carriers/shipments?status=${statusName}&page=${this.page}`,
            config
          )
          .then((response) => {
            if (response.status === 200) {
              this.shipmentList.push(...response.data.data);
              this.maxPages = response.data.last_page;
              if (this.shipmentList.length === 0) {
                this.shipmentList = [];
                if (statusID === 0)
                  this.errorStatus = "No active shipment exist";
                if (statusID === 10)
                  this.errorStatus = "No pending shipment exist";
                if (statusID === 13)
                  this.errorStatus = "No completed shipment exist";
              } else this.errorStatus = "";
            }
            if (response.status === 401) {
              this.authMixins.clearAuthData();
              this.$router.push("/signin");
            }
            this.request[statusName] = false;
            this.isLoading = false;
            this.requestStatus = false;
          })
          .catch(async (err) => {
            this.requestStatus = false;
            this.isLoading = false;
            this.shipmentList = [];
            this.request[statusName] = false;
            this.errorStatus = "Unable to fetch the shipments";

            if (err.response && err.response.status === 401) {
              this.authMixins.clearAuthData();
              this.$router.push("/signin");
            }

            console.log(err);
          })
          .finally(() => {
            this.isLoadingNewPage = false;
          });
      }
    },

    async loadDrivers() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      await this.$api.get(`drivers/list`, config)
        .then((response) => {
          if (response.status === 200) {
            let tempDrivers = response.data.data;
            this.driversList = tempDrivers;
            this.errorStatus = "";
          }
        })
        .catch(async (err) => {
          console.log(err);
          this.errorStatus = "No drivers exist";
        });
    },

    orderAccept(status) {
      if (status && !this.selectedDriver) {
        this.$toast.error("Please select a driver", {});
        return;
      }

      if (!this.requestStatus) {
        this.requestStatus = true;
        this.acceptOrderDialog = false;

        let config = {
          headers: {
            Authorization: localStorage.authToken,
          },
        };

        let driver = {
          driver_id: this.selectedDriver ? this.selectedDriver.id : null,
        };

        let orderUrl = "";
        if (status)
          orderUrl = `carriers/shipments/${this.selectedShipmentId}/accept`;
        else orderUrl = `carriers/shipments/${this.selectedShipmentId}/decline`;

        this.$api.patch(orderUrl, driver, config)
          .then((response) => {
            if (response.status === 200) {
              if (status) this.$toast.info("Order accepted successfully", {});
              else this.$toast.info("Order has been rejected", {});

              this.shipmentList.splice(this.selectedShipmentIndex, 1);
              this.loadShipments();
            }
            this.requestStatus = false;
          })
          .catch(async (err) => {
            this.requestStatus = false;
            if (err.response && err.response.data.code === 110001 && err.response.data.message == "Carrier shipment mismatch") {
              this.$toast.error("Order has been accepted by a different carrier", {});
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              this.$toast.error("Order update failed", {});
            }
            console.log(err);
          });
      }
    },
    getLocationUrl(location) {
      if (!location) return "";
      if (location.source === 2) {
        const address = [
          location.line1,
          location.line2,
          location.city,
          location.state,
          location.zip,
        ]
          .filter((segment) => segment && !segment.startsWith("_void_"))
          .join(", ");

        return `https://maps.google.com/maps?z=11&t=m&q=loc:${encodeURIComponent(
          address
        )}`;
      }

      return `https://maps.google.com/maps?z=11&t=m&q=loc:${location.latitude}+${location.longitude}`;
    },
  },
};
</script>
