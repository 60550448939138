<template>
  <div class="bar">
    <div v-if="impersonationData" class="bar-data">
      Impersonating <b>{{ impersonationData.name }}</b> @{{
        impersonationData.company
      }}
      <span class="spacer">|</span>
      <span class="drop" @click="dropSession">Drop Session</span>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/auth.mixin.js";

export default {
  name: "ImpersonationBar",
  data: () => ({
    authMixins: mixin.methods,
    impersonationData: null,
  }),
  created() {
    this.checkImpersonation();

    setInterval(() => {
      if (!localStorage.impersonationToken) {
        this.impersonationData = null;
      }
    }, 1000);
  },
  methods: {
    dropSession() {
      this.authMixins.clearAuthData();
      this.$router.push("/signin");
      this.impersonationData = null;
    },
    checkImpersonation() {
      let adminToken = this.$route.query["admin-token"];
      let impersonationToken = this.$route.query["impersonation-token"];

      if (!adminToken || !impersonationToken) {
        impersonationToken = localStorage.getItem("impersonationToken");
        adminToken = localStorage.getItem("token");
      }

      if (!adminToken && !impersonationToken) {
        return;
      }

      this.authMixins.clearAuthData();
      this.authMixins.saveAuthSignin(true, adminToken);
      localStorage.setItem("impersonationToken", impersonationToken);
      localStorage.setItem("token", adminToken);

      this.$api.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
      this.$api.defaults.headers.common["X-Impersonate"] = impersonationToken;

      this.$api.get("auth/admin/impersonate/infos").then(({ data }) => {
        this.impersonationData = data.data;
      });

      if (
        this.$route.query["admin-token"] ||
        this.$route.query["impersonation-token"]
      ) {
        this.$router.replace("/homepage");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bar {
  display: flex;
  justify-content: center;

  .bar-data {
    font-family: "Outfit", sans-serif;
    font-size: 16px;

    background-color: #3ab03d;
    padding: 2px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .spacer {
      margin: 0 10px;
    }

    .drop {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
