import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";

import "./assets/styles/index.scss";
// import * as VueGoogleMaps from "vue2-google-maps"
import VueZoomer from "vue-zoomer";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import LogRocket from "logrocket";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
import * as Sentry from "@sentry/vue";
import mixin from "./mixins/auth.mixin.js";
import "./mixins/api.mixin.js";
import apiMixin from "@/mixins/api.mixin";
if (process.env.VUE_APP_LOG_ROCKET) {
  LogRocket.init(process.env.VUE_APP_LOG_ROCKET);
}

Vue.component("AppLoading", Loading);
Vue.config.productionTip = false;

// "https://dev.api.breaker19.app/api/v1/"

Vue.use(VueCroppie);
Vue.use(VueZoomer);
Vue.use(VueToast);

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_GOOGLE_MAP,
//     libraries: "places,drawing,visualization"
//   }
// });

if (
  process.env.VUE_APP_SENTRY_ENVIRONMENT &&
  process.env.VUE_APP_SENTRY_ENVIRONMENT != "local"
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_PROJECT_URL, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.mixin(apiMixin)

new Vue({
  router,
  store,
  vuetify,
  data() {
    return {
      authMixins: mixin.methods,
    }
  },
  render: (h) => h(App),
  mounted() {
    this.$api.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            mixin.methods.clearAuthData();
            if (router.currentRoute.path === "/signin") return;
            router.push({ name: "signin" });
          }
          return Promise.reject(error);
        }
    );

    window.addEventListener('keyup', (e) => {
      if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== "development") {
        return;
      }

      switch (e.key) {
        case 'F9': {
          const confirmed = confirm("Reset API base URL do default?");

          if (!confirmed) {
            return;
          }

          const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
          this.$api.defaults.baseURL = apiBaseUrl;
          localStorage.setItem("apiBaseURL", apiBaseUrl);
          this.authMixins.clearAuthData();

          if (this.$router.currentRoute.path !== "/signin") {
            this.$router.push({ name: "signin" });
          }

          return;
        }

        case 'F10': {
          const currentURL = localStorage.getItem("apiBaseURL");
          console.log(currentURL)

          const apiBaseUrl = prompt("Enter API base URL", currentURL || "");

          if (!apiBaseUrl ||  this.$api.defaults.baseURL === apiBaseUrl) {
            return;
          }

          this.$api.defaults.baseURL = apiBaseUrl;
          localStorage.setItem("apiBaseURL", apiBaseUrl);
          this.authMixins.clearAuthData();

          if (this.$router.currentRoute.path !== "/signin") {
            this.$router.push({ name: "signin" });
          }

          return;
        }
      }
    });
  }
}).$mount("#app");
