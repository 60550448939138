<template>
  <div class="signIn-styles">
    <section class="section-container fill-height auth-module">
      <v-row class="signin d-flex justify-center" style="height: 100vh; position: relative;">
        <div class="logo mb-lg-3" style="padding-top: 100px;">
          <a href="https://breaker19.app"><img :src="require('../assets/icons/full_logo.svg')" width="100%"></a>
        </div>
        <div class="white-back">
          <div style="margin-top: -160px;" class="content-container">
            <div class="notFound-img-container d-flex justify-center">
              <img :src="require('../assets/images/404.gif')" class="notFound-img">
            </div>
            <div class="d-flex justify-center unavailable-msg-container">
              <p class="unavailable-msg">It seems that this page is not available.</p>
            </div>
            <div class="btn-container">
              <v-btn @click.stop="navigateToHomepage()" type="button" class="go-to-home-btn white--text"
                  height="64px" block color="#191919">
                  Go to home
              </v-btn>
            </div>
            <div class="d-flex justify-center mt-5">
              <p class="contact-msg text-center">
                If you are facing some issues, get in touch with the support team:  <a href="mailto:dispatch@breaker19.app" class="support-link">dispatch@breaker19.app.</a>
              </p>
            </div>
          </div>
        </div>
      </v-row>
    </section>
  </div>
</template>

<style scoped lang="scss">
.signIn-styles {
  @import '../assets/styles/SignIn.scss';
  @import '../assets/styles/PageNotFound.scss';
  font-family: 'Outfit', sans-serif;
  background-color: #F4F4F4;
}
</style>
  
<script>

export default {
  data() {
    return {
    }
  },

  mounted() {
    document.title = "Page Not Found - Breaker19.app";
  },
  methods: {
    navigateToHomepage() {
      this.$router.push('/homepage');
    }
  }
}
</script>